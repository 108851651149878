/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Recursive_300Light = require('./Recursive_300Light.ttf');
export const Recursive_400Regular = require('./Recursive_400Regular.ttf');
export const Recursive_500Medium = require('./Recursive_500Medium.ttf');
export const Recursive_600SemiBold = require('./Recursive_600SemiBold.ttf');
export const Recursive_700Bold = require('./Recursive_700Bold.ttf');
export const Recursive_800ExtraBold = require('./Recursive_800ExtraBold.ttf');
export const Recursive_900Black = require('./Recursive_900Black.ttf');
